import React, {useState} from "react";

const Accord = ({title, children}) => {
    const [isOpen, setOpen] = React.useState(false);
    return (
        <div className="accordion-wrapper">

            <div
                className={`accordion-title ${isOpen ? "open" : ""}`}
                onClick={() => setOpen(!isOpen)}
            >
                {title}
            </div>
            <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
                <div className="accordion-content">{children}</div>
            </div>
        </div>
    );
};

function Accordion() {
    return (<div className="wrapper">
        <Accord title="¿Que es el Arbitraje?">
            <p style={{color: '#000000'}}>El arbitraje consiste en aprovechar la diferencia de
                precio que tienen las criptomonedas en los distintos Exchanges. Las operaciones
                se
                hacen al instante y siempre son ganadoras.
            </p>
            <p style={{color: '#000000'}}>Dentro de los mercados mundiales los grandes fondos de
                inversión, operadores profesionales, bancos e instituciones emplean estrategias
                específicas para generar diferentes grados de rentabilidad en las negociaciones
                de
                productos financiero como acciones, bonos e incluso criptomonedas.
            </p>
            <p style={{color: '#000000'}}>Entre estas innumerables técnicas se encuentra el
                arbitraje, una práctica que consiste en realizar operaciones de compra y venta
                de un
                activo con el fin de beneficiarse de la diferencia de precios que estos tengan
                en
                los mercados.</p>
            <p style={{color: '#000000'}}>En palabras más prácticas la empresa compra el activo
                digital donde es más barato y lo vende en exchanges donde resulta ser más
                caro.</p>
        </Accord>
        <Accord title="¿Es legal el Arbitraje?">
            <p style={{color: '#000000'}}>Totalmente. Es una tipología de comercio. No es
                Trading. Es comprar barato y vender caro, como se ha hecho desde siempre en todos los mercados.</p>
        </Accord>
        <Accord title="¿Se puede saturar el mercado del Arbitraje?">
            <p style={{color: '#000000'}}>Es una posibilidad, pero para esto debe haber cientos
                de miles de usuarios y que
                el mercado de criptomonedas, usuarios y Exchanges no evolucione para que una
                persona tenga un rendimiento de 0. </p>
            <p style={{color: '#000000'}}>Sin embargo, nuestra empresa siempre monitoreará el
                rendimiento mediante
                algoritmos para mantener el mercado bajo vigilancia, y los rendimientos de los
                usuarios sigan fluyendo.</p>
            <p style={{color: '#000000'}}>Los mercados de criptomonedas se encuentran en una
                etapa temprana. </p>
            <p style={{color: '#000000'}}>El intercambio de criptomonedas no está regulado y
                está muy desarticulado.</p>
            <p style={{color: '#000000'}}>Además, la comunicación de información entre exchanges
                es lenta y hay menos
                operadores (y menos competencia) en comparación con otros mercados de inversión
                populares.
            </p>
            <p style={{color: '#000000'}}>Por todas estas razones, podrían generarse más
                oportunidades potenciales de
                arbitraje.</p>
            <p style={{color: '#000000'}}>El concepto de operar con arbitraje no es algo nuevo y
                ha existido en los
                mercados de acciones, bonos y divisas desde hace muchos años. Sin embargo, el
                desarrollo de sistemas cuantitativos diseñados para detectar diferencias de
                precios y ejecutar operaciones en mercados separados ha hecho que las
                operaciones de arbitraje estén fuera del alcance de la mayoría de los
                comerciantes casuales.</p>
        </Accord>
    </div>)
}

export default Accordion
