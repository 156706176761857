import React from "react";
import Layout from "../components/Layout";
import {Link} from "gatsby";

//components
import Accordion from "../components/Accordion";
import Breadcrums from "../components/Breadcrums";

const Service_one = () => {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div className="services_banner"
                         style={{backgroundImage: `url(../images/levenmas/atencio_reque.jpg)`}}>
                        <h1>Atención de Requerimiento</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                    </div>
                    <Breadcrums name="Atención de Requerimiento"/>
                    <div className="card">
                        <div className="card__content">
                            <h1 style={{color: '#000000', fontSize: 20}}>Los principales son:</h1>
                            <div>
                                <ul style={{color: '#000000'}}>
                                    <li>DESBLOQUEO DE SELLOS DIGITALES</li>
                                    <li>OBLIGACIONES FISCALES OMITIDAS</li>
                                    <li>CRÉDITOS FISCALES</li>
                                    
                                </ul>
                            </div>
                            <div>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Service_one
